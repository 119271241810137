<template>
  <div
    :class="number ? 'nb-card-icon' : 'nb-card-icon-no-number'"
    :style="`width: ${width}px; padding: ${padding}`"
    @mouseover="emitMouseOver()"
  >
    <div v-if="number" class="mr-3 mb-2">
      <IconNumberBall width="29" :number="number" />
    </div>
    <div class="w-100">
      <section class="mb-4 d-flex justify-content-between">
        <div>
          <div class="heading-3 mb-1">
            {{ title }}
            <span
              v-if="completeBadge"
              class="nb-badge"
              :class="isSellerDone ? 'bg-success-10' : 'bg-warning-10'"
              >{{
                isSellerDone ? `${$t("complete")}` : `${$t("incomplete")}`
              }}</span
            >
            <NbHelpText
              v-if="helpText"
              :id="`${id}-popover`"
              class="mx-1"
              :size="helpTextSize"
              placement="topright"
            >
              {{ helpText }}
            </NbHelpText>
          </div>
          <div class="body-3">
            {{ subTitle }}
          </div>
        </div>
        <div v-if="cardSwitch" class="ml-4">
          <NbSwitch
            v-if="cardSwitch.type === 'switch'"
            :class="cardSwitch.inputClass"
            :required="cardSwitch.required"
            :id="cardSwitch.id"
            :name="cardSwitch.name"
            :error="errors[cardSwitch.key] || cardSwitch.error"
            :options="cardSwitch.options"
            v-model="content[cardSwitch.key]"
          />
        </div>
      </section>
      <hr v-if="cardSwitch && !cardSwitch.hiddeHr" class="mb-4 mt-0" />
      <div class="d-flex justify-content-between flex-wrap">
        <div
          v-for="formInput in formInputs"
          :key="formInput.id"
          :class="formInput.inputGroupClass"
        >
          <div :class="formInput.inputClass">
            <div v-if="formInput.label" class="input-label">
              {{ formInput.label }} {{ formInput.required ? "*" : "" }}
            </div>

            <NbSelectInput
              v-if="formInput.type === 'select'"
              :required="formInput.required"
              :id="formInput.id"
              :name="formInput.name"
              :helpText="formInput.helpText"
              :error="errors[formInput.key] || formInput.error"
              :options="formInput.options"
              v-model="content[formInput.key]"
            />

            <NbPassInput
              v-else-if="formInput.type === 'password'"
              :required="formInput.required"
              :id="formInput.id"
              :name="formInput.name"
              :helpText="formInput.helpText"
              :disabled="formInput.disabled || false"
              :placeholder="formInput.placeholder"
              :autocomplete="formInput.autocomplete || ''"
              :error="errors[formInput.key] || formInput.error"
              v-model="content[formInput.key]"
            />

            <NbSwitch
              v-else-if="formInput.type === 'switch'"
              :required="formInput.required"
              :id="formInput.id"
              :name="formInput.name"
              :helpText="formInput.helpText"
              :error="errors[formInput.key] || formInput.error"
              v-model="content[formInput.key]"
            />

            <NbCheckbox
              v-else-if="formInput.type === 'checkbox'"
              :required="formInput.required"
              :id="formInput.id"
              :name="formInput.name"
              :helpText="formInput.helpText"
              :error="errors[formInput.key] || formInput.error"
              v-model="content[formInput.key]"
            />

            <NbRadio
              v-else-if="formInput.type === 'radio'"
              :required="formInput.required"
              :id="formInput.id"
              :name="formInput.name"
              :helpText="formInput.helpText"
              :error="errors[formInput.key] || formInput.error"
              :options="formInput.options"
              v-model="content[formInput.key]"
            />

            <NbTextInput
              v-else
              :required="formInput.required"
              :id="formInput.id"
              :name="formInput.name"
              :helpText="formInput.helpText"
              :disabled="formInput.disabled || false"
              :placeholder="formInput.placeholder"
              :autocomplete="formInput.autocomplete || ''"
              :error="errors[formInput.key] || formInput.error"
              v-model="content[formInput.key]"
            />
          </div>

          <Alert
            v-if="formInput.comment"
            class="mb-3"
            :type="formInput.comment.type"
            :icon="formInput.comment.icon"
            :message="formInput.comment.message"
          />
        </div>
      </div>
      <NbButton
        v-if="actionBtn"
        :disabled="disabled"
        :data-toggle="modalTarget ? 'modal' : ''"
        :data-target="modalTarget ? '#' + modalTarget : ''"
        @click="$emit('actionCliked')"
        class="mb-3 float-right"
        size="sm"
      >
        {{ actionBtn }}
      </NbButton>
    </div>
  </div>
</template>

<script>
import IconNumberBall from "../icons/IconNumberBall.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbSelectInput from "@/components/input/select/NbSelectInput.vue";
import NbPassInput from "@/components/input/text/NbPassInput.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbSwitch from "@/components/buttons/NbSwitch.vue";
import NbCheckbox from "@/components/buttons/NbCheckbox.vue";
import NbRadio from "@/components/buttons/NbRadio.vue";
import Alert from "@/components/alerts/Alert.vue";
import NbHelpText from "@/components/generic/NbHelpText.vue";

export default {
  components: {
    NbSelectInput,
    IconNumberBall,
    NbTextInput,
    NbPassInput,
    NbButton,
    NbSwitch,
    NbCheckbox,
    NbRadio,
    Alert,
    NbHelpText,
  },
  props: {
    id: {
      type: String,
      require: false,
    },
    value: {
      required: false,
    },
    number: {
      type: Number,
      require: false,
    },
    formInputs: {
      type: Array,
      require: true,
    },
    title: {
      type: String,
      require: false,
    },
    subTitle: {
      type: String,
      require: false,
    },
    cardSwitch: {
      type: Object,
      required: false,
    },
    completeBadge: {
      type: Boolean,
      default: false,
    },
    actionBtn: {
      type: String,
      required: false,
    },
    width: {
      type: [Number, String],
      required: false,
    },
    padding: {
      type: [Number, String],
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    modalTarget: {
      type: String,
      required: false,
    },
    helpText: {
      type: String,
      required: false,
    },
    helpTextSize: {
      type: String,
      required: false,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      formToEmit: {},
      content: this.value,
      timer: null,
      emitOver: true,
    };
  },
  created() {
    //this.loadFormToEmit();
  },
  methods: {
    emitMouseOver() {
      //avoid send many mouse over!!!
      if (this.emitOver) {
        this.$emit("mouseover");
        this.emitOver = false;
      }
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.emitOver = true;
      }, 300);
    },
    //inicializa o form
    /* 
    loadFormToEmit() {
      let formKeys = {};
      this.formInputs.forEach(formInput => {
        formKeys[formInput.key] = '';

      })
      this.formToEmit = JSON.parse(JSON.stringify(formKeys))
    }
    */
  },
  computed: {
    isSellerDone() {
      let isDone = false;
      let count = 0;

      const filteredFormToEmit = this.formInputs.filter((formInput) => {
        if (formInput.required) {
          return formInput;
        }
      });
      filteredFormToEmit.forEach((element) => {
        if (this.content[element.key]) {
          count++;
          return;
        }
        isDone = false;
      });

      if (count >= filteredFormToEmit.length) {
        isDone = true;
      }

      return isDone;
    },
  },
  watch: {
    //altera o input (way data bind ->)
    content: {
      deep: true,
      handler(newValue) {
        this.$emit("input", newValue);
        this.$emit("formChange", this.content);
      },
    },
    //altera o input vindo do pai (way data bind <-)
    value(newValue) {
      this.content = newValue;
    },
  },
};
</script>

<style scoped>
.nb-card-icon {
  transition: all 0.3s ease;
  background: var(--white);
  border-radius: 0.25rem;
  padding: 2rem 5.62rem 2rem 3.12rem;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.nb-card-icon-no-number {
  transition: all 0.3s ease;
  background: var(--white);
  border-radius: 0.25rem;
  padding: 1.5rem 1.5rem 0.44rem 1.5rem;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
</style>
