var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loaded)?_c('LoadingPage'):_c('div',[_c('NbPageTitle',{attrs:{"title":_vm.$t('printingsPage.title'),"subTitle":_vm.$t('printingsPage.standardConfig')}}),_c('section',{staticClass:"px-3 d-flex justify-content-start"},[_c('section',{staticClass:"left-section"},[_c('NbCardForm',{staticClass:"mb-2",attrs:{"id":"form-labels_configuration","width":_vm.width,"title":_vm.$t('printingsPage.shippingLabel'),"subTitle":_vm.$t('printingsPage.shippingLabelSubtitle'),"errors":_vm.errors,"helpText":_vm.$t('printingsPage.switchHelpText'),"cardSwitch":{
          id: 'labels_configuration',
          type: 'switch',
          key: 'labels_configuration',
          inputClass: 'mt-3',
        },"formInputs":_vm.shippingLabelInputs},on:{"actionCliked":function($event){return _vm.updateProfile()},"mouseover":function($event){return _vm.updateLabelExamlpe('labels_configuration')}},model:{value:(_vm.formInputs),callback:function ($$v) {_vm.formInputs=$$v},expression:"formInputs"}}),_c('NbCardForm',{staticClass:"mb-2",attrs:{"id":"form-commercial_invoice","width":_vm.width,"title":_vm.$t('printingsPage.commercialInvoice'),"subTitle":_vm.$t('printingsPage.commercialInvoiceSubtitle'),"errors":_vm.errors,"helpText":_vm.$t('printingsPage.switchHelpText'),"cardSwitch":{
          id: 'commercial_invoice',
          type: 'switch',
          key: 'commercial_invoice',
          inputClass: 'mt-3',
        },"formInputs":_vm.commercialInvoiceInputs},on:{"actionCliked":function($event){return _vm.updateProfile()},"mouseover":function($event){return _vm.updateLabelExamlpe('commercial_invoice')}},model:{value:(_vm.formInputs),callback:function ($$v) {_vm.formInputs=$$v},expression:"formInputs"}}),_c('NbCardForm',{staticClass:"mb-2",attrs:{"id":"form-packing_slips","width":_vm.width,"title":_vm.$t('printingsPage.packingSlip'),"subTitle":_vm.$t('printingsPage.packingSlipLabelSubtitle'),"errors":_vm.errors,"helpText":_vm.$t('printingsPage.switchHelpText'),"cardSwitch":{
          id: 'packing_slips',
          type: 'switch',
          key: 'packing_slips',
          inputClass: 'mt-3',
        },"formInputs":_vm.packingSlipInputs},on:{"actionCliked":function($event){return _vm.updateProfile()},"mouseover":function($event){return _vm.updateLabelExamlpe('packing_slips')}},model:{value:(_vm.formInputs),callback:function ($$v) {_vm.formInputs=$$v},expression:"formInputs"}}),_c('NbCardForm',{staticClass:"mb-2",attrs:{"id":"form-if_print_confirmation","width":_vm.width,"title":`${_vm.$t('printingsPage.baterryConfirmation')}`,"subTitle":_vm.$t('printingsPage.batterySubtitle'),"errors":_vm.errors,"helpText":_vm.$t('printingsPage.switchHelpText'),"cardSwitch":{
          id: 'if_print_confirmation',
          type: 'switch',
          key: 'if_print_confirmation',
          inputClass: 'mt-3',
        },"formInputs":_vm.cevaBatteryInputs},on:{"actionCliked":function($event){return _vm.updateProfile()},"mouseover":function($event){return _vm.updateLabelExamlpe('baterry_confirmation')}},model:{value:(_vm.formInputs),callback:function ($$v) {_vm.formInputs=$$v},expression:"formInputs"}}),_c('NbCardForm',{staticClass:"mb-0",attrs:{"id":"form-dangerous_good","width":_vm.width,"title":_vm.$t('printingsPage.dangerousGood'),"subTitle":_vm.$t('printingsPage.dangerousGoodSubtitle'),"errors":_vm.errors,"helpText":_vm.$t('printingsPage.switchHelpText'),"cardSwitch":{
          id: 'dangerous_good',
          type: 'switch',
          key: 'dangerous_good',
          inputClass: 'mt-3',
        },"formInputs":_vm.dangerousGoodInputs},on:{"actionCliked":function($event){return _vm.updateProfile()},"mouseover":function($event){return _vm.updateLabelExamlpe('dangerous_good')}},model:{value:(_vm.formInputs),callback:function ($$v) {_vm.formInputs=$$v},expression:"formInputs"}}),_c('NbCardForm',{class:_vm.formInputs.dangerous_use_personal_number
            ? 'anim-accordion-active mb-2'
            : 'anim-accordion-disabled mb-4',staticStyle:{"margin-top":"-25px","padding":"0rem 1.5rem 0.44rem","max-height":"200px"},attrs:{"id":"form-dangerous_use_personal_numberInputs","width":_vm.width,"errors":_vm.errors,"formInputs":_vm.dangerous_use_personal_numberInputs},model:{value:(_vm.formInputs),callback:function ($$v) {_vm.formInputs=$$v},expression:"formInputs"}}),_c('NbCardForm',{staticClass:"mb-2",attrs:{"id":"form-msds","width":_vm.width,"title":_vm.$t('printingsPage.msds'),"subTitle":_vm.$t('printingsPage.msdsSubtitle'),"errors":_vm.errors,"helpText":_vm.$t('printingsPage.switchHelpText'),"cardSwitch":{
          id: 'msds',
          type: 'switch',
          key: 'msds',
          inputClass: 'mt-3',
          hiddeHr: true,
        }},on:{"actionCliked":function($event){return _vm.updateProfile()},"mouseover":function($event){return _vm.updateLabelExamlpe('msds')}},model:{value:(_vm.formInputs),callback:function ($$v) {_vm.formInputs=$$v},expression:"formInputs"}}),_c('NbCardForm',{staticClass:"mb-2",attrs:{"id":"form-addressDevolutionCustom","width":_vm.width,"title":_vm.$t('printingsPage.devolutionAddress'),"subTitle":_vm.$t('printingsPage.userDevolutionAddress'),"errors":_vm.errors,"helpText":_vm.$t('printingsPage.devolutionCustomHelpText'),"cardSwitch":{
          id: 'addressDevolutionCustom',
          type: 'switch',
          key: 'addressDevolutionCustom',
          inputClass: 'mt-3',
          hiddeHr: true,
        }},on:{"actionCliked":function($event){return _vm.updateProfile()}},model:{value:(_vm.formInputs),callback:function ($$v) {_vm.formInputs=$$v},expression:"formInputs"}}),_c('div',{staticClass:"bg-gray-05 text-center py-3"},[_c('span',{staticClass:"body-2"},[_vm._v(_vm._s(_vm.$t("printingsPage.ifYouPrefer"))+" ")]),_c('router-link',{staticClass:"link-1",attrs:{"to":`/notifications/tracking`}},[_vm._v(" "+_vm._s(_vm.$t("printingsPage.theTrackingPage"))+" > ")])],1)],1),_c('section',{staticClass:"right-section w-100 ml-5"},[_c('div',{staticClass:"bg-gray-05 label-card-wrapper"},[_c('div',{staticClass:"heading-2"},[_vm._v(_vm._s(_vm.$t("printingsPage.documentExample")))]),_c('div',{staticClass:"label-img",class:_vm.siwtchFadeAnim ? 'anim-fade-one' : 'anim-fade-two'},[_c('img',{staticClass:"bg-gray-05",attrs:{"src":require(`../../assets/img/label-examples/${_vm.currentLabelExample}.png`)}})]),_c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"body-2"},[_vm._v(" "+_vm._s(_vm.$t("printingsPage.wantToLearnAboutLabels"))+"? ")]),_c('a',{staticClass:"link-1",attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.$t("checkOurTutorial")))])])])])]),_c('NbFooter',{attrs:{"text":_vm.$t('components.footer.checkDocumentsAndTags'),"link":"https://nobordistinc.freshdesk.com/support/home"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }