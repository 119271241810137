<template>
  <LoadingPage v-if="loaded" />
  <div v-else>
    <NbPageTitle
      :title="$t('printingsPage.title')"
      :subTitle="$t('printingsPage.standardConfig')"
    />

    <section class="px-3 d-flex justify-content-start">
      <section class="left-section">
        <NbCardForm
          id="form-labels_configuration"
          class="mb-2"
          :width="width"
          @actionCliked="updateProfile()"
          @mouseover="updateLabelExamlpe('labels_configuration')"
          :title="$t('printingsPage.shippingLabel')"
          :subTitle="$t('printingsPage.shippingLabelSubtitle')"
          :errors="errors"
          :helpText="$t('printingsPage.switchHelpText')"
          :cardSwitch="{
            id: 'labels_configuration',
            type: 'switch',
            key: 'labels_configuration',
            inputClass: 'mt-3',
          }"
          :formInputs="shippingLabelInputs"
          v-model="formInputs"
        />

        <NbCardForm
          id="form-commercial_invoice"
          class="mb-2"
          :width="width"
          @actionCliked="updateProfile()"
          @mouseover="updateLabelExamlpe('commercial_invoice')"
          :title="$t('printingsPage.commercialInvoice')"
          :subTitle="$t('printingsPage.commercialInvoiceSubtitle')"
          :errors="errors"
          :helpText="$t('printingsPage.switchHelpText')"
          :cardSwitch="{
            id: 'commercial_invoice',
            type: 'switch',
            key: 'commercial_invoice',
            inputClass: 'mt-3',
          }"
          :formInputs="commercialInvoiceInputs"
          v-model="formInputs"
        />

        <NbCardForm
          id="form-packing_slips"
          class="mb-2"
          :width="width"
          @actionCliked="updateProfile()"
          @mouseover="updateLabelExamlpe('packing_slips')"
          :title="$t('printingsPage.packingSlip')"
          :subTitle="$t('printingsPage.packingSlipLabelSubtitle')"
          :errors="errors"
          :helpText="$t('printingsPage.switchHelpText')"
          :cardSwitch="{
            id: 'packing_slips',
            type: 'switch',
            key: 'packing_slips',
            inputClass: 'mt-3',
          }"
          :formInputs="packingSlipInputs"
          v-model="formInputs"
        />

        <NbCardForm
          id="form-if_print_confirmation"
          class="mb-2"
          :width="width"
          @actionCliked="updateProfile()"
          @mouseover="updateLabelExamlpe('baterry_confirmation')"
          :title="`${$t('printingsPage.baterryConfirmation')}`"
          :subTitle="$t('printingsPage.batterySubtitle')"
          :errors="errors"
          :helpText="$t('printingsPage.switchHelpText')"
          :cardSwitch="{
            id: 'if_print_confirmation',
            type: 'switch',
            key: 'if_print_confirmation',
            inputClass: 'mt-3',
          }"
          :formInputs="cevaBatteryInputs"
          v-model="formInputs"
        />

        <NbCardForm
          id="form-dangerous_good"
          class="mb-0"
          :width="width"
          @actionCliked="updateProfile()"
          @mouseover="updateLabelExamlpe('dangerous_good')"
          :title="$t('printingsPage.dangerousGood')"
          :subTitle="$t('printingsPage.dangerousGoodSubtitle')"
          :errors="errors"
          :helpText="$t('printingsPage.switchHelpText')"
          :cardSwitch="{
            id: 'dangerous_good',
            type: 'switch',
            key: 'dangerous_good',
            inputClass: 'mt-3',
          }"
          :formInputs="dangerousGoodInputs"
          v-model="formInputs"
        />

        <NbCardForm
          id="form-dangerous_use_personal_numberInputs"
          :class="
            formInputs.dangerous_use_personal_number
              ? 'anim-accordion-active mb-2'
              : 'anim-accordion-disabled mb-4'
          "
          :width="width"
          style="
            margin-top: -25px;
            padding: 0rem 1.5rem 0.44rem;
            max-height: 200px;
          "
          :errors="errors"
          :formInputs="dangerous_use_personal_numberInputs"
          v-model="formInputs"
        />

        <NbCardForm
          id="form-msds"
          class="mb-2"
          :width="width"
          @actionCliked="updateProfile()"
          @mouseover="updateLabelExamlpe('msds')"
          :title="$t('printingsPage.msds')"
          :subTitle="$t('printingsPage.msdsSubtitle')"
          :errors="errors"
          :helpText="$t('printingsPage.switchHelpText')"
          :cardSwitch="{
            id: 'msds',
            type: 'switch',
            key: 'msds',
            inputClass: 'mt-3',
            hiddeHr: true,
          }"
          v-model="formInputs"
        />

        <NbCardForm
          id="form-addressDevolutionCustom"
          class="mb-2"
          :width="width"
          @actionCliked="updateProfile()"
          :title="$t('printingsPage.devolutionAddress')"
          :subTitle="$t('printingsPage.userDevolutionAddress')"
          :errors="errors"
          :helpText="$t('printingsPage.devolutionCustomHelpText')"
          :cardSwitch="{
            id: 'addressDevolutionCustom',
            type: 'switch',
            key: 'addressDevolutionCustom',
            inputClass: 'mt-3',
            hiddeHr: true,
          }"
          v-model="formInputs"
        />

        <!-- 
        <div class="text-right mt-2 pb-4">
          <NbButton size="sm" @click="addNewConfiguration">{{ $t('save') }}</NbButton>
        </div> 
        -->

        <div class="bg-gray-05 text-center py-3">
          <span class="body-2">{{ $t("printingsPage.ifYouPrefer") }} </span>
          <router-link :to="`/notifications/tracking`" class="link-1">
            {{ $t("printingsPage.theTrackingPage") }} >
          </router-link>
        </div>
      </section>
      <section class="right-section w-100 ml-5">
        <div class="bg-gray-05 label-card-wrapper">
          <div class="heading-2">{{ $t("printingsPage.documentExample") }}</div>
          <div
            class="label-img"
            :class="siwtchFadeAnim ? 'anim-fade-one' : 'anim-fade-two'"
          >
            <img
              class="bg-gray-05"
              :src="
                require(`../../assets/img/label-examples/${currentLabelExample}.png`)
              "
            />
          </div>
          <div class="mt-3">
            <div class="body-2">
              {{ $t("printingsPage.wantToLearnAboutLabels") }}?
            </div>
            <a href="#" class="link-1">{{ $t("checkOurTutorial") }}</a>
          </div>
        </div>
      </section>
    </section>

    <NbFooter
      :text="$t('components.footer.checkDocumentsAndTags')"
      link="https://nobordistinc.freshdesk.com/support/home"
    />
  </div>
</template>
<script>
import PrintingService from "../../services/PrintingService";
import SellerService from "../../services/SellerService";
import LoadingPage from "@/components/loadings/LoadingPage.vue";
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbCardForm from "@/components/forms/NbCardForm.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";

const printingService = new PrintingService();
const sellerService = new SellerService();

export default {
  name: "Printings",
  components: { LoadingPage, NbPageTitle, NbCardForm, NbFooter },
  data: () => ({
    loaded: true,
    sellerId: null,
    errors: {},
    formInputs: {
      labels_configuration: null,
      commercial_invoice: null,
      packing_slips: null,
      if_print_confirmation: null,
      dangerous_good: null,
      addressDevolutionCustom: null,
      msds: null,
      dangerous_use_personal_number: null,
      dangerous_personal_number: null,
      //Shipping Label
      letter_label: null,
      zpl_label: null,
      //Comercial Invoice
      zpl_commercial_invoice: null,
      letter_invoice: null,
      cn15_label: null,
      //PackSlip
      packingSlipSelected: "",
      zpl_packing_slip: null,
      half_packing_slip: null,
      letter_packing_slip: null,
      //battery confirm
      ceva_battery_confirmation: null,
    },
    width: 524,
    timer: null,
    isSaving: false,
    currentLabelExample: "labels_configuration",
    siwtchFadeAnim: true,
  }),
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      sellerService.getCurrentSeller().then((response) => {
        this.sellerId = response.data.data.id;
      });

      printingService
        .getPrintings()
        .then((response) => {
          Object.assign(this.formInputs, response.data.data); //impede que o tipo da variavel da data fique undefined;

          if (this.formInputs.zpl_packing_slip) {
            this.formInputs.packingSlipSelected = "Thermal-Printer";
          } else if (this.formInputs.half_packing_slip) {
            this.formInputs.packingSlipSelected = "Half-Page";
          } else {
            this.formInputs.packingSlipSelected = "Full-Page";
          }

          //wait for computed and watch methods avoiding the autosave when load page
          setTimeout(() => {
            this.loaded = false;
          }, 500);
        })
        .catch((error) => {
          this.$helpers.toast(
            error.message || error.msg || "Request failed",
            "danger"
          );
        });
    },
    addNewConfiguration() {
      const dataToSend = JSON.parse(JSON.stringify(this.formInputs));
      dataToSend.seller = {
        id: this.sellerId,
        use_seller_devolution_address: this.formInputs.addressDevolutionCustom,
      };

      printingService
        .updatePrinting(dataToSend)
        .then(() => {
          /* Nao enviar toast
        this.noErrorsOnSave(this.$t('printingsPage.saveSuccess'));
        */
          this.siwtchIsSaving(false);
        })
        .catch((error) => {
          this.errorsOnSave();
          if (error.response.data.messages[0]) {
            this.warningOnSave(error.response.data.messages[0]);
            this.formInputs.addressDevolutionCustom = false;
          }
          this.siwtchIsSaving(false);
        });
    },
    noErrorsOnSave(message) {
      this.$helpers.toast(message, "success");
    },
    errorsOnSave() {
      this.$helpers.toast(this.$t("printingsPage.saveTryLater"), "success");
    },
    warningOnSave(message) {
      this.$helpers.toast(message, "warning");
    },

    siwtchIsSaving(savingVal) {
      if (typeof savingVal === "boolean") {
        this.isSaving = savingVal;
      } else {
        this.isSaving = !this.isSaving;
      }

      this.$root.$emit("isSaving", this.isSaving);
    },

    saveUnsavedWork() {
      this.siwtchIsSaving(true);
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.addNewConfiguration();
      }, 2500);
    },
    updateLabelExamlpe(currentExample) {
      if (currentExample != this.currentLabelExample) {
        this.currentLabelExample = currentExample;
      }
    },
  },
  computed: {
    twoPrintingOptions() {
      return [
        { text: this.$t("printingsPage.letterSizeFull"), value: false },
        { text: this.$t("printingsPage.thermalPrinter"), value: true },
      ];
    },
    threePrintingOptions() {
      return [
        { text: this.$t("printingsPage.letterSizeFull"), value: "Full-Page" },
        { text: this.$t("printingsPage.letterSizeHalf"), value: "Half-Page" },
        {
          text: this.$t("printingsPage.thermalPrinter"),
          value: "Thermal-Printer",
        },
      ];
    },
    shippingLabelInputs() {
      return [
        {
          required: true,
          type: "checkbox",
          id: "cn15_label",
          label: "Add Label",
          name: "Add appendix label",
          inputClass: "mb-3 w-100",
          key: "cn15_label",
        },
        {
          required: true,
          type: "radio",
          options: this.twoPrintingOptions,
          id: "zpl_label",
          label: "Sheet Size",
          inputClass: "mb-3 w-70",
          key: "zpl_label",
        },
      ];
    },
    commercialInvoiceInputs() {
      return [
        {
          required: true,
          type: "radio",
          options: this.twoPrintingOptions,
          id: "zpl_commercial_invoice",
          label: "Sheet Size",
          comment: {
            type: "warning",
            icon: "fas fa-exclamation-circle",
            message:
              "Commercial invoices printed on thermal paper may not be accepted in some countries. Rejected packages will be returned to sender",
          },
          inputClass: "mb-3 w-70",
          key: "zpl_commercial_invoice",
        },
      ];
    },
    packingSlipInputs() {
      return [
        {
          required: true,
          type: "radio",
          options: this.threePrintingOptions,
          id: "packingSlipSelected",
          label: "Sheet Size",
          inputClass: "mb-3 w-70",
          key: "packingSlipSelected",
        },
      ];
    },
    cevaBatteryInputs() {
      return [
        {
          required: true,
          type: "radio",
          options: this.twoPrintingOptions,
          id: "ceva_battery_confirmation",
          label: "Sheet Size",
          inputClass: "mb-3 w-70",
          key: "ceva_battery_confirmation",
        },
      ];
    },
    dangerousGoodInputs() {
      return [
        {
          required: false,
          type: "checkbox",
          id: "dangerous_use_personal_number",
          label: this.$t("printingsPage.ifDangerousPersonal"),
          name: "Contact number that will be used in case of any problems",
          inputClass: "mb-3 w-100",
          key: "dangerous_use_personal_number",
        },
      ];
    },
    dangerous_use_personal_numberInputs() {
      return [
        {
          required: true,
          type: "input",
          id: "dangerous_personal_number",
          label: this.$t("phone"),
          placeholder: "00000000000",
          comment: {
            type: "warning",
            icon: "fas fa-exclamation-circle",
            message:
              "Please input the phone number here. (Obs: The label should be printed color for so that there's no zpl for it)",
          },
          inputClass: "mb-3 w-48-5",
          key: "dangerous_personal_number",
        },
      ];
    },
  },
  watch: {
    //auto save
    formInputs: {
      deep: true,
      handler() {
        if (!this.loaded) {
          this.saveUnsavedWork();
        }
      },
    },
    //Shipping Label
    "formInputs.zpl_label": function () {
      this.formInputs.letter_label = !this.formInputs.zpl_label;
    },

    //Comercial Invoice
    "formInputs.zpl_commercial_invoice": function () {
      this.formInputs.letter_invoice = !this.formInputs.zpl_commercial_invoice;
    },

    //Ceva Battery Confirmation
    "formInputs.ceva_battery_confirmation": function () {
      this.formInputs.letterCeva = !this.formInputs.ceva_battery_confirmation;
    },

    //PackSlip
    "formInputs.packingSlipSelected": function () {
      this.formInputs.zpl_packing_slip = false;
      this.formInputs.half_packing_slip = false;
      this.formInputs.letter_packing_slip = false;

      if (this.formInputs.packingSlipSelected === "Thermal-Printer") {
        this.formInputs.zpl_packing_slip = true;
        return;
      }
      if (this.formInputs.packingSlipSelected === "Half-Page") {
        this.formInputs.half_packing_slip = true;
        return;
      }
      if (this.formInputs.packingSlipSelected === "Full-Page") {
        this.formInputs.letter_packing_slip = true;
        return;
      }
    },

    currentLabelExample() {
      this.siwtchFadeAnim = !this.siwtchFadeAnim;
    },
  },
};
</script>

<style scoped>
.right-section {
  position: sticky;
  top: -1.6rem;
  right: 1rem;
  transition: all 0.3s ease;
  text-align: center;
  max-width: calc(75vw - 524px);
  max-height: 81vh;
}
.label-card-wrapper {
  transition: all 0.3s ease;
  border-radius: 0.5rem;
  padding: 1rem 3rem;
  background: var(--gray-05);
  margin: auto;
  width: fit-content;
}
.label-card-wrapper .label-img {
  overflow-x: hidden; /* Hide horizontal scrollbar */
  overflow-y: auto; /* Add vertical scrollbar */
  max-height: calc(81vh - 3.5rem);
}
.label-card-wrapper .label-img img {
  transition: all 0.3s ease;
  object-fit: contain;
  margin-top: 1rem;
  max-width: calc(75vw - 38.7rem);
}
</style>
