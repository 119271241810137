import HttpService from "./HttpService";

export default class PrintingService {
  constructor() {
    this.httpService = HttpService;
  }

  getPrintings() {
    return this.httpService.get("/v1/printing");
  }
  getPrinting(id) {
    return this.httpService.get("/v1/printing/" + id);
  }
  createPrinting(data) {
    return this.httpService.post("/v1/printing", data);
  }
  updatePrinting(data) {
    data = {
      printing: data,
    };
    return this.httpService.put("/v1/printing", data);
  }
  allPrinting(ids) {
    return this.httpService.put("/v1/printings/all", ids);
  }
  multipleInvoice(ids) {
    return this.httpService.post("v1/printings/multiple_invoice", {
      ids: ids,
    });
  }
  multiShipperConfirmations(orderIds = {}) {
    return this.httpService.post(
      "/v1/printings/generate_multiple_shipper_confirmation",
      orderIds
    );
  }
  multiplePackingSlips(ids) {
    return this.httpService.post("/v1/printings/multiple_packing_slip", {
      ids: ids,
    });
  }
  multipleLabel(ids) {
    return this.httpService.post("/v1/printings/multiple_label", {
      ids: ids,
    });
  }

  printingAll(ids) {
    return this.httpService.post("/v1/printings/print_all", {
      ids: ids,
    });
  }
}
